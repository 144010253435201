declare var mixpanel: any;
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { SendRequestService } from "../../../services/send-request.service";
import { Location } from "@angular/common";
import { HttpHeaders, HttpParams } from "@angular/common/http";
// import * as mixpanel from 'mixpanel-browser';

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input() showIcons: boolean = true;
  @Output() onToggleSidebar = new EventEmitter();

  showFiller = false;
  loggedIn: boolean = false;
  currentUser: any;
  apps: Array<any>;
  tables: any[] = [];
  selectedTableName: string;
  // pingResult: any;
  version: string;
  apiStatusActive: boolean = true;
  poller: any;
  reports: any[] = [];
  loading: boolean = false;
  dashboards: any;
  navigationLoading: boolean = false;
  uiControls: any = {};

  notification: any;
  notificationCount: number;
  progress: boolean = false;
  homePage: string = '/home';
  showMenulist: boolean = false;
  tableList: any;
  selectedTable: any = {};
  appList: any;
  selectedApp: any;
  setupApp: string;
  profileImage: string;
  initNavBarComplete: boolean;
  navbar_data: any = {};
  drawerControls: any;
  currentPage: number = 0;
  bookmarks: any[];
  startRow: number = 0;
  pageSize: number = 10;
  isMenuOpen = false;
  totalCount: number = 0;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public readonly location: Location,
    private apiService: SendRequestService,
    private el: ElementRef
  ) {
  }

  isPathStartWith(value: string) {
    const path = this.router.url;
    return path && path.startsWith(value);
  }

  ngOnInit() {
    this.loggedIn = this.apiService.isLoggedIn();
    this.currentUser = this.apiService.getCurrentUser();
    this.poller = setInterval(() => {
      this.loggedIn = this.apiService.isLoggedIn();
      this.currentUser = this.apiService.getCurrentUser();
      if (!this.currentUser) {
        this.tables = undefined;
        this.currentUser = undefined;
        this.navbar_data = undefined;
      }
      const path = this.router.url;
      if (!this.loggedIn) {
        this.apiService.setSelectedAppControls({});
        if (path && this.apiService.requireLoginPath(path) && !this.isPathStartWith("/login")) {
          this.router.navigate(["/login"], { queryParams: { go: path } });
        }
        this.homePage = "/home";
      }
      else {
        if (this.isPathStartWith("/login")) {
          const go = this.route.snapshot.queryParams["go"];
          if (go) {
            this.router.navigateByUrl(go);
          }
        }
        if (!this.initNavBarComplete) {
          this.initNavBar();
        }
        this.homePage = this.getHomePage();
      }


    }, 1000);




  }

  ngOnDestroy(): void {
    if (this.poller) {
      clearInterval(this.poller);
      this.poller = undefined;
    }
  }

  initNavBar() {
    if (this.apiService.isLoggedIn()) {
      this.loadNotificationsCount();
      this.loadNavResponseData();
      if (this.currentUser?.guid) {
        this.profileImage = `/api/v1/users/profile-pic/${this.currentUser.guid}`;
      } else {
        this.profileImage = undefined;
      }
      this.initNavBarComplete = true;
    }



  }


  toggleSideBar() {
    if (this.loggedIn && this.apiStatusActive) {
      this.onToggleSidebar.emit({});
    }
  }

  loadNotificationsCount() {
    this.apiService.getRequest(`/notifications/count`).subscribe((res) => {
      const error = this.apiService.checkError(res);
      if (error) {
        this.apiService.notify("error", error);
        return;
      }
      this.totalCount = res.value;
      this.notificationCount = res.value > 100 ? "99+" : res.value;

    });
  }


  loadNotifications() {
    this.progress = true;
    let path = `/notifications`;
    let params = new HttpParams();
    params = params.append('page', this.currentPage);
    params = params.append('size', this.pageSize);

    this.apiService.getRequest(path, params).subscribe((res) => {
      const error = this.apiService.checkError(res);
      if (error) {
        this.progress = false;
        this.apiService.notify("error", error);
        return;
      }

      this.progress = false;
      this.notification = res.records;
      if (this.currentPage === 0) {
        this.setupBookmarks(0);
      }

    });
  }

  deleteNotifications() {


    this.apiService.confirm(`Are you sure to delete all notifications?`).subscribe((result) => {
      if (result) {


        this.apiService.deleteRequest(`/notifications/`).subscribe((response) => {
          const error = this.apiService.checkError(response);
          if (error) {
            return;
          }
          this.loadNotificationsCount();
        });
      }
    });
  }

  deleteSingleNotification(item: any) {


    this.apiService.confirm(`Are you sure to delete  notifications? \n${item.message}`).subscribe((result) => {
      if (result) {
        this.apiService.deleteRequest(`/notifications/${item.id}`).subscribe((response) => {
          const error = this.apiService.checkError(response);
          if (error) {
            this.apiService.notify("error", error);
            return;
          }
          this.currentPage = 0;
          this.loadNotificationsCount();
        });

      }
    });
  }

  getHomePage() {
    if (this.loggedIn) {
      const user = this.apiService.getCurrentUser();
      if (user && user.home_page) {
        return user.home_page;
      }
      return '/default-home';
    }
    return '/home';
  }

  selectTable(tableName: string) {

    this.tableList.forEach((table) => {
      if (table.name === tableName) {
        this.selectedTable = { table_name: table.name, table_label: table.label };
      }

    })

  }

  redirectPage() {
    if (this.selectedTable.table_name) {
      if (this.selectedTable.table_name && this.selectedTable.name) {
        this.router.navigate(['/search'], { queryParams: { table: this.selectedTable.table_name, name: this.selectedTable.name } });
      } else {
        this.router.navigate(['/search'], { queryParams: { table: this.selectedTable.table_name } });
      }
    }


  }

  loadTableList() {

    this.apiService.getNavbarResponse().subscribe((response) => {

      const error = this.apiService.checkError(response);
      if (error) {
        this.apiService.notify("error", error);
      } else {
        this.tableList = response.tables;



      }
    });
  }

  shrinkBox(event: Event) {
    if (this.el.nativeElement.contains(event.target)) {
      this.showMenulist = false;
    }
  }



  selectApp(app: any) {
    this.selectedApp = app.label;
    this.loadDrawerControls(app.name);
    let payload = { app_name: app.name }
    this.apiService.saveUserPreference("navbar", JSON.stringify(payload));
  }


  loadPreference() {
    this.apiService.getUserPreference('navbar').subscribe((res) => {
      let error = this.apiService.checkError(res);
      if (error) {
        this.apiService.notify("error", error);
        return;
      }

      this.appList?.forEach((app) => {
        if (app.name === res.app_name) {
          this.selectApp(app);
        }
      })
    });


  }


  updateAppList(navResponse: any) {

    this.appList = [];


    if (Array.isArray(navResponse?.apps) && navResponse.apps.length > 0) {
      navResponse.apps.forEach(app => {

        if (app === "Platform") {
          this.appList.push({ name: app, label: 'Platform', link: '/default-home' });
        } else if (app === "RuleEngine") {
          this.appList.push({ name: app, label: 'Rule Engine', link: '/cpe' });
        } else if (app === "LearningApp") {
          this.appList.push({ name: app, label: 'Learning App', link: '/learning-app' });
        } else if (app === "WorkflowApp") {
          this.appList.push({ name: app, label: 'Workflow App', link: '/workflow-app' });
        } else if (app === "FirewallApp") {
          this.appList.push({ name: app, label: 'Network Traffic Analysis', link: '/firewall' });
        } else if (app === "ProductReviews") {
          this.appList.push({ name: app, label: 'Product Reviews App', link: '/default-home' });
        } else if (app === "CRM") {
          this.appList.push({ name: app, label: "CRM App", link: '/crm' });
        } else {
          this.appList.push({ name: app, label: app, link: '/default-home' });
        }
      });

    }

  }

  loadDrawerControls(appName: string) {
    if (!appName) {
      return;
    }
    let path = `/users/sidebar/${appName}`;
    this.apiService.getRequest(path).subscribe((response) => {
      let error = this.apiService.checkError(response);
      if (error) {
        this.apiService.notify('error', error);
        return;
      }
      this.drawerControls = response;
      this.apiService.setSelectedAppControls(response);


    })
  }


  onImageHandler() {
    this.profileImage = "/assets/images/svgs/profile-avatar.svg";
  }

  loadNavResponseData() {
    this.apiService.getNavbarResponse(true).subscribe(navbar_data => {
      const error = this.apiService.checkError(navbar_data);
      if (error) {
        console.error("Failed to get navbar data", error);
        return;
      }
      if (navbar_data) {
        this.navbar_data = navbar_data;
        this.tableList = this.navbar_data.tables;
        this.currentUser = this.navbar_data.user;
        this.updateAppList(navbar_data);
        this.loadPreference();
        this.initClickTracking(navbar_data);
        if (navbar_data.selected_app) {
          this.loadDrawerControls(navbar_data.selected_app);
          this.appList.forEach(r => {
            if (r.name === navbar_data.selected_app) {
              this.selectedApp = r.label;
            }
          });
        }
      }
    });
  }


  initClickTracking(navbar_data: any): void {
    if (!this.apiService.mixpanelInitized && navbar_data.enable_web_tracking && navbar_data.user?.guid) {
      var headers = new HttpHeaders().append("content-type", "application/json");
      const config = {
        debug: false,
        track_pageview: true,
        persistence: 'localStorage',
        ignore_dnt: true,
        api_host: navbar_data.web_tracking_url
      };
      mixpanel.init(navbar_data.web_tracking_id, config);
      mixpanel.identify(navbar_data.user.guid);
      this.apiService.mixpanelInitized = true;
    }

  }

  // pagination code start
  setupBookmarksLastSet() {
    let lowest = this.bookmarks[0].page;
    this.setupBookmarks(lowest - 10);
  }


  setupBookmarksNextSet() {
    let highest = this.bookmarks[this.bookmarks.length - 1].page;
    this.setupBookmarks(highest + 1);
  }



  hasMoreBookmarkPages(): boolean {
    let highest = 1;
    this.bookmarks.forEach((bookmark) => {
      highest = Math.max(highest, bookmark.page);
    });


    const more = this.totalCount - ((highest + 1) * this.pageSize);

    if (more > 0) {
      return true;
    } else {
      return false;
    }

  }




  setupBookmarks(j: number = 0) {
    if (!this.pageSize) {
      this.pageSize = 10;
    }
    this.bookmarks = [];
    this.currentPage = j;
    for (let i = j * this.pageSize; i < this.totalCount; i = i + this.pageSize) {
      this.bookmarks.push({
        page: j,
        startRow: i,
        pageSize: this.pageSize,
      });
      ++j;
      if (this.bookmarks.length == 10) {
        break;
      }
    }
  }



  bookmarkClicked(bookmark: any) {
    if (bookmark) {
      this.currentPage = bookmark.page;
      this.startRow = bookmark.startRow;
      this.pageSize = bookmark.pageSize;
    }

    this.loadNotifications();
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }



}
