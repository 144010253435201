// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	//apiURL: 'http://einext02:8080/api/v1/',
	apiURL: '/api/v1/',
	// apiURL: 'http://localhost:8080/api/v1/',
	// apiURL: 'http://192.168.1.2:8080/api/v1/',
	enableSchemaCaching: false,
	site_key: '6LfqTMUpAAAAAN2Fjnq48YuhotBLb7QP_PnPjdZq'

};
