import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";

import { Location } from "@angular/common";
import { SendRequestService } from "src/app/services/send-request.service";
// import { MatSidenavModule } from "@angular/material/sidenav";
// import { FieldWrapperComponent } from "../field-wrapper/field-wrapper.component";
// import { MatIconModule } from "@angular/material/icon";
// import { MatTooltipModule } from '@angular/material/tooltip';

import { Subscription } from "rxjs";

export class NavigationButton {
  serial: number;
  label: string;
  routing: string;
  icon: string;
  active: boolean;
  ui_control: string;
  visible: boolean;
}

@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.scss"],
  // standalone: true,
  // imports: [CommonModule,    RouterModule, MatSidenavModule, FieldWrapperComponent, MatIconModule, MatTooltipModule],
})
export class DrawerComponent implements OnInit, OnDestroy {
  @Input() showIcons: boolean = true;
  @Output() onToggleSidebar = new EventEmitter();
  showFiller1 = false;
  showFiller2 = false;
  showFiller3 = false;
  showFiller4 = false;
  showFiller5 = false;
  showFiller6 = false;
  showFiller7 = false;
  showFiller8 = false;
  loggedIn: boolean = false;
  currentUser: any;
  apps: Array<any>;
  selectedAppId: string;
  tables: any[] = [];
  selectedTableName: string;
  pingResult: any;
  version: string;
  apiStatusActive: boolean = true;
  poller: any;
  reports: any[] = [];
  loading: boolean = false;
  dashboards: any;
  showNewDashbord: boolean = false;
  uiControls: any = {};
  drawerControls: any = {};
  subscription: Subscription;
  navigationButtons: NavigationButton[] = [];

  sidebarAuth = ['/home', '/login', '/signup', '/reset-password', '/change-password', '/verify-email'
    , '/invoice-page/', '/product-details', '/product-search', '/contact-us', '/quiz-attempts/', '/landing', '/invoice', '/complete-payment', '/oauth2-login-code/'];

  private navigationButtonsData: any[] = [
    {
      label: "Workbench",
      routing: "/entity-extracts",
      icon: "account_tree",
      ui_control: "knowledge_workbench",
      active: false,
      visible: false,
    },

    {
      ui_control: "knowledge_graph_settings",
      routing: "/entity-extracts-settings",
      label: "Workbench Settings",
      icon: "settings",
      active: false,
      visible: false,
    },

    {
      label: "SQL Query",
      routing: "/query",
      icon: "join_left",
      ui_control: "sql_query",
      active: false,
      visible: false,
    },

    {
      ui_control: "dashboard",
      routing: "/dashboards",
      label: "Dashboard",
      icon: "dashboard",
      active: false,
      visible: false,
    },

    {
      ui_control: "report",
      routing: "/reports",
      label: "Reports",
      icon: "description",
      active: false,
      visible: false,
    },

    {
      ui_control: "file",
      routing: "/file-explorer",
      label: "Files",
      icon: "hard_drive",
      active: false,
      visible: false,
    },

    {
      ui_control: "email_templates",
      routing: "/email-templates",
      label: "Email Templates",
      icon: "stacked_inbox",
      active: false,
      visible: false,
    },
    {
      ui_control: "scheduled_job",
      routing: "/jobs",
      label: "Scheduled Job",
      icon: "build_circle",
      active: false,
      visible: false,
    },
    {
      ui_control: "query_summary",
      routing: "/search-summary",
      label: "Query Summary",
      icon: "summarize",
      active: false,
      visible: false,
    },
    {
      ui_control: "parser",
      routing: "/parsers",
      label: "Parsers",
      icon: "linked_services ",
      active: false,
      visible: false,
    },

    {
      ui_control: "remote_agent",
      routing: "/remote-agents'",
      label: "Remote Agents ",
      icon: "support ",
      active: false,
      visible: false,
    },

    {
      ui_control: "kv_store",
      routing: "/cfs",
      label: "KV Store ",
      icon: "action_key ",
      active: false,
      visible: false,
    },

    {
      ui_control: "playbook",
      routing: "/playbooks",
      label: "Playbook ",
      icon: "component_exchange ",
      active: false,
      visible: false,
    },

    {
      ui_control: "web_hooks",
      routing: "/webhooks",
      label: "Webhooks",
      icon: "webhook ",
      active: false,
      visible: false,
    },

    {
      ui_control: "tables",
      routing: "/tables",
      label: "Tables ",
      icon: "database ",
      active: false,
      visible: false,
    },

    {
      ui_control: "global_config",
      routing: "/global-app-config",
      label: "Global App Configs",
      icon: "admin_panel_settings ",
      active: false,
      visible: false,
    },

    {
      ui_control: "acc_config",
      routing: "/account-app-config",
      label: "Account App Configs",
      icon: "supervisor_account ",
      active: false,
      visible: false,
    },

    {
      ui_control: "global_user_search",
      routing: "/users-search",
      label: "Global User Search",
      icon: "data_loss_prevention ",
      active: false,
      visible: false,
    },

    {
      ui_control: "global_account_search",
      routing: "/account-search",
      label: "Global Account Search",
      icon: "person_play ",
      active: false,
      visible: false,
    },

    {
      ui_control: "rules",
      routing: "/rules",
      label: "Rules ",
      icon: "model_training ",
      active: false,
      visible: false,
    },

    {
      ui_control: "indicator_dashboard",
      routing: "/rule-dashboard",
      label: "Indicator Dashboard",
      icon: "dashboard ",
      active: false,
      visible: false,
    },

    {
      ui_control: "indicator_dashboard",
      routing: "/rule-dashboard",
      label: "Indicator Dashboard",
      icon: "dashboard ",
      active: false,
      visible: false,
    },

    {
      ui_control: "permission_set",
      routing: "/permission-set",
      label: "Permission Set",
      icon: "security ",
      active: false,
      visible: false,
    },

    {
      ui_control: "campaign",
      routing: "/crm/campaigns",
      label: "Campaigns",
      icon: "campaign ",
      active: false,
      visible: false,
    },

    {
      ui_control: "customers",
      routing: "/crm/customers",
      label: "Customers",
      icon: "groups ",
      active: false,
      visible: false,
    },

    {
      ui_control: "message_templates",
      routing: "/crm/message-templates",
      label: "Message Templates",
      icon: "view_timeline ",
      active: false,
      visible: false,
    },

    {
      ui_control: "campaign_settings",
      routing: "/crm/campaign-settings",
      label: "Campaigns Settings",
      icon: "settings_account_box ",
      active: false,
      visible: false,
    },

    {
      ui_control: "message_analytics",
      routing: "/crm/wa-templates",
      label: "Message Analytics",
      icon: "monitoring ",
      active: false,
      visible: false,
    },

    {
      ui_control: "products",
      routing: "/crm/products",
      label: "Products",
      icon: "category ",
      active: false,
      visible: false,
    },

    {
      ui_control: "orders",
      routing: "/crm/orders",
      label: "Orders",
      icon: "orders ",
      active: false,
      visible: false,
    },

    {
      ui_control: "promotions",
      routing: "/crm/promotions",
      label: "Promotion",
      icon: "sell ",
      active: false,
      visible: false,
    },

    {
      ui_control: "web_tracking",
      routing: "/webtrackings",
      label: "Web Tracking",
      icon: "search_insights ",
      active: false,
      visible: false,
    },
    {
      ui_control: "leads",
      routing: "/crm/leads",
      label: "Leads",
      icon: "guardian ",
      active: false,
      visible: false,
    },
    ,
    {
      ui_control: "cases",
      routing: "/crm/cases",
      label: "Cases",
      icon: "cases ",
      active: false,
      visible: false,
    },
    {
      ui_control: "payment_report",
      routing: "/crm/payment-reports",
      label: "Payment Reports",
      icon: "payments ",
      active: false,
      visible: false,
    },

    {
      ui_control: "workflows",
      routing: "/workflows",
      label: "Workflows",
      icon: "rebase ",
      active: false,
      visible: false,
    },

    {
      ui_control: "workflow_feeds",
      routing: "/workflows/feeds",
      label: "Workflow Feeds",
      icon: "forum ",
      active: false,
      visible: false,
    },

    {
      ui_control: "workflow_dashboard",
      routing: "/workflows/dashboard",
      label: "Workflow Dashboard ",
      icon: "dashboard ",
      active: false,
      visible: false,
    },

    {
      ui_control: "work_list",
      routing: "/workflows-tasklogs",
      label: "Work List",
      icon: "published_with_changes ",
      active: false,
      visible: false,
    },
    {
      ui_control: "work_list",
      routing: "/workflows/invocations",
      label: "Workflows Invocations",
      icon: "pin_invoke ",
      active: false,
      visible: false,
    },

    {
      ui_control: "users",
      routing: "/users",
      label: "Users",
      icon: "person ",
      active: false,
      visible: false,
    },

    {
      ui_control: "user_groups",
      routing: "/user-groups",
      label: "User Groups",
      icon: "group ",
      active: false,
      visible: false,
    },
    {
      ui_control: "quiz_dashboard",
      routing: "/learning-app",
      label: "Learning App",
      icon: "school "
    },
    {
      ui_control: "quizzes",
      routing: "/quizzes",
      label: "Quizzes",
      icon: "quiz ",
    },

    {
      ui_control: "quiz_submissions",
      routing: "/quizzes/submission",
      label: "Submissions",
      icon: "full_coverage ",
    },

  ];




  preferences: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public location: Location,
    public apiService: SendRequestService
  ) {
    this.navigationButtons = [];
    let i = 0;
    for (let r of this.navigationButtonsData) {
      let obj = { ...r };
      if (obj['routing']) {
        obj.serial = i;
        obj.active = false;
        obj.visible = false;
        const d = Object.assign(new NavigationButton(), obj);
        this.navigationButtons.push(d);
        ++i;
      }
    }
  }

  ngOnInit() {
    this.loggedIn = this.apiService.isLoggedIn();
    this.poller = setInterval(() => {
      this.loggedIn = this.apiService.isLoggedIn();
    }, 3000);


    this.subscription = this.apiService.getSelectedAppControls().subscribe((controls) => {

      if (!this.apiService.isEmpty(controls)) {
        this.drawerControls = controls;
        this.setNavigationButtonVisibility();
        this.setActiveRouter();
        this.loadPreference();

      } else {
        this.drawerControls = undefined;
      }

    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveRouter();
      }
    });

  }

  ngOnDestroy(): void {
    if (this.poller) {
      clearInterval(this.poller);
      this.poller = undefined;
    }
    this.subscription.unsubscribe();
  }

  toggleSideBar() {
    this.apiService.drawerOpen = !this.apiService.drawerOpen;
    if (this.loggedIn && this.apiStatusActive) {
      this.onToggleSidebar.emit({});
    }
  }


  setNavigationButtonVisibility() {
    this.navigationButtons?.forEach((c) => {
      if (this.drawerControls[c.ui_control]) {
        c.visible = true;
      } else {
        c.visible = false;

      }
    });
  }

  setActiveRouter() {

    if (!Array.isArray(this.navigationButtons)) {
      return;
    }

    const buttons = this.navigationButtons;
    buttons.sort((a, b) => a.routing.length > b.routing.length ? -1 : 1);
    for (let r of buttons) {
      r.active = false;
    }

    for (let r of buttons) {
      if (this.router.url.startsWith(r.routing)) {
        r.active = true;
        this.preferences["route"] = r;
        this.savePreference();
        break;
      }
    }

    buttons.sort((a, b) => a.serial < b.serial ? -1 : 1);

  }



  loadPreference() {

    this.apiService.getUserPreference('drawer').subscribe((res) => {
      let error = this.apiService.checkError(res);
      if (error) {
        this.apiService.notify("error", error);
        return;
      }

      this.preferences = res;

      // if (this.preferences.route?.routing) {
      //   this.router.navigate([this.preferences.route.routing]);
      // }


    });
  }


  savePreference() {
    this.apiService.saveUserPreference('drawer', this.preferences);
  }

}
