<div>
  <mat-toolbar class="av-nav-container" (click)="shrinkBox($event)">
    <!-- <div class="av-logo-container"> -->

    <a class="brand-logo" [routerLink]="[homePage]" *ngIf="loggedIn">
      <img src="/assets/images/avalanchio-logo.png" alt="logo" class="logo-image" />
    </a>
    <a class="brand-logo" [routerLink]="['/home']" *ngIf="!loggedIn">
      <img src="/assets/images/avalanchio-logo.png" alt="logo" class="logo-image" />
    </a>
    <!-- </div> -->



    <div class="search-box" *ngIf="navbar_data?.view_search">


      <div (click)="$event.stopPropagation()" class="">
        <div class="d-flex">
          <ng-container *ngIf="showMenulist">
            <button mat-stroked-button class="search-menu-dd-btn" [matMenuTriggerFor]="searchMenu">
              {{selectedTable?.table_label ? selectedTable.table_label : "Search"}}
              <mat-icon class=" material-symbols-outlined">arrow_drop_down</mat-icon></button>
            <mat-menu #searchMenu="matMenu" class="table-name">
              <ng-container *ngFor="let table of tableList">
                <div mat-menu-item class="w200" (click)="selectTable(table.name)">
                  {{table.label}}
                </div>
              </ng-container>
            </mat-menu>
          </ng-container>
          <div class="input-box" [ngClass]="{'input-box-width':showMenulist}">
            <mat-icon class="input-box-icon pointer material-symbols-outlined fw-bold"
              (click)="redirectPage()">search</mat-icon>
            <input autocomplete="off" class="search-input" type="text" (focus)="showMenulist = true"
              [(ngModel)]="selectedTable.name" (keydown.enter)="redirectPage()" placeholder="Search" />
          </div>
        </div>
      </div>
    </div>


    <!-- right block -->
    <div class="av-flex gap-3 usermenu" *ngIf="loggedIn && currentUser?.id">
      <ng-container *ngIf="appList?.length > 1">

        <button mat-flat-button class="w150 flex-row-reverse gap-3 justify-content-center me-2 d-none d-md-flex"
          [matMenuTriggerFor]="menuapps">
          <span class="ellipse-text">
            {{selectedApp || 'Select App'}}
          </span>
          <mat-icon class="rotateup text-center material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menuapps="matMenu">
          <ng-container>
            <a *ngFor="let app of appList" mat-menu-item [routerLink]="[app.link]" (click)="selectApp(app)">
              <span>{{app.label}}</span>
            </a>
          </ng-container>
        </mat-menu>

      </ng-container>

      <button mat-icon-button class="d-none d-md-block" *ngIf="navbar_data?.view_setup" [routerLink]="['/setup']">
        <mat-icon class=" material-symbols-outlined">
          settings
        </mat-icon>
      </button>

      <button mat-icon-button [matMenuTriggerFor]="notificationMenu" (click)="loadNotifications()">
        <mat-icon class=" material-symbols-outlined" aria-hidden="false" matBadge={{notificationCount}}
          matBadgePosition="after" matBadgeColor="primary">
          notifications
        </mat-icon>
      </button>
      <mat-menu #notificationMenu="matMenu" class="notification-box pb-0">
        <div class="custom-progress-bar">
          <mat-progress-bar mode="indeterminate" *ngIf="progress"></mat-progress-bar>
        </div>
        <ng-container *ngIf="notification?.length > 0">
          <section class="notifications av-lower-tiles notification-section px-1">
            <div class="d-flex justify-content-between">
              <h4 class="notification-heading">Notifications</h4>
              <a class="link" href="javascript:void(0)" (click)="deleteNotifications()">Delete All</a>
            </div>

            <div style="height: 400px; overflow-y: auto; scrollbar-width: thin;">
              <div class="av-flow-root">
                <div class="av-table-container">
                  <div class="notifications-list  w-100 pt-2 pb-1" *ngFor="let event of notification">
                    <div class="notification-record  w-100">
                      <div class="notification-bubble" *ngIf="!event.visible">
                        {{ event.message }}
                      </div>
                      <div *ngIf="event.visible" class="">{{ event.message }}</div>
                      <div>
                        <small class="notification-time secondary-text" *ngIf="event.created_on">{{
                          event.created_on | date : apiService.TIMESTAMP_FORMAT
                          }}</small>
                        <small class="notification-status secondary-text" *ngIf="event.status">{{ event.status
                          }}</small>
                        <a class="link show-delete-btn small" href="javascript:void(0)"
                          (click)="deleteSingleNotification(event)">
                          Delete
                        </a>
                      </div>

                    </div>
                    <mat-divider></mat-divider>
                    <div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- pagination -->
            <div class="pt-3 d-flex justify-content-end">
              <div (click)="$event.stopPropagation()">
                <div class="d-flex justify-content-end">
                  <ng-container *ngIf="bookmarks?.length > 0">
                    <div class="d-flex flex-wrap p-1 no_selection">
                      <div class="page-no" (click)="setupBookmarksLastSet()" *ngIf="bookmarks[0].page >= 10">
                        <span class="bookmark-btn">
                          <mat-icon class="material-symbols-outlined pagination-icon">
                            keyboard_double_arrow_left
                          </mat-icon>
                        </span>
                      </div>
                      <div class="page-no" *ngFor="let bookmark of bookmarks" (click)="bookmarkClicked(bookmark)"
                        title="{{ bookmark.startRow }}">
                        <span class="bookmark-btn" [ngClass]="{ 'active-bookmark': currentPage === bookmark.page }">
                          {{ bookmark.page + 1 }}
                        </span>
                      </div>
                      <div class="page-no" (click)="setupBookmarksNextSet()" *ngIf="hasMoreBookmarkPages()">
                        <span class="bookmark-btn">
                          <mat-icon class="material-symbols-outlined pagination-icon">
                            keyboard_double_arrow_right
                          </mat-icon>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </section>
        </ng-container>

        <ng-container *ngIf="notification?.length === 0">
          <button mat-menu-item>No Notifications</button>
        </ng-container>

      </mat-menu>



      <span *ngIf="!apiStatusActive" title="API is down" class="dot"></span>
      <a [matMenuTriggerFor]="ProfileOptions">
        <div class="av-flex pe-3">

          <img [src]="profileImage" *ngIf="profileImage" class="profile-image" (error)="onImageHandler()"
            alt="avatar" />
        </div>
      </a>
      <mat-menu #ProfileOptions="matMenu" class="user-menu">
        <div class="user-menu-info">
          <ng-container>
            <div class="user-menu-img">
              <img [src]="profileImage" class="user-menu-img" (error)="onImageHandler()" alt="profile" />
            </div>
          </ng-container>

          <p>{{ currentUser?.name }}</p>
          <p class="av-email">{{ currentUser?.email }}</p>
        </div>
        <div class="user-menu-items">
          <a mat-menu-item class="av-user-info-btn" [routerLink]="['/user-profile']">
            <i class="bi bi-person-fill fa-large me-2" aria-hidden="true"></i>
            Profile
          </a>
          <a mat-menu-item class="user-menu-item" [routerLink]="['/accounts']"
            *ngIf="navbar_data?.view_account_details">
            <i class="bi bi-person-vcard me-2" aria-hidden="true"></i> Account
            Details
          </a>
          <a mat-menu-item class="user-menu-item" [routerLink]="['/support-cases']"
            *ngIf="navbar_data?.my_support_case">
            <i class="bi bi-life-preserver me-2" aria-hidden="true"></i> My Support
            Cases
          </a>
          <a mat-menu-item class="user-menu-item" [routerLink]="['/reset-password', currentUser.guid]">
            <i class="bi bi-key-fill fa-large me-2" aria-hidden="true"></i>Reset
            Password
          </a>
          <a class="user-menu-item" mat-menu-item href="/logout?go=%2Fhome">
            <i class="bi bi-box-arrow-in-right fa-large me-2" aria-hidden="true"></i>
            Logout
          </a>
        </div>
      </mat-menu>
    </div>
    <div class="av-nav-login-btn" *ngIf="!loggedIn && !location.path().startsWith('/login')">
      <a class="link" href="/login" target="_self">
        <button mat-stroked-button class="link">Login</button>
      </a>
    </div>
  </mat-toolbar>
</div>
<div class="mb46"></div>