declare var mixpanel: any;
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Pipe, PipeTransform } from "@angular/core";

// import { TemplateRendererComponent } from "../component/layout/template-renderer/template-renderer.component";
import { DatePipe, DecimalPipe, Location } from "@angular/common";
import { Observable, Subject, forkJoin, BehaviorSubject } from "rxjs";

import * as mixpanel from 'mixpanel-browser';
import { MatSnackBar } from "@angular/material/snack-bar";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";


@Pipe({ name: "Json", standalone: true })
export class Json implements PipeTransform {
  // Not working
  transform(obj: any): string {
    if (obj) {
      return JSON.stringify(obj);
    }
    return "";
  }
}

@Pipe({ name: "HumanBytes", standalone: true })
export class HumanReadableBytes implements PipeTransform {
  transform(bytes: number, si = true, dp = 1): string {
    if (bytes == null) {
      return null;
    }

    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }
}

@Pipe({ name: "HumanMillis", standalone: true })
export class HumanReadableMillis implements PipeTransform {
  transform(milliseconds: number): string {
    let temp = milliseconds / 1000;
    const years = Math.floor(temp / 31536000),
      days = Math.floor((temp %= 31536000) / 86400),
      hours = Math.floor((temp %= 86400) / 3600),
      minutes = Math.floor((temp %= 3600) / 60),
      seconds = temp % 60;

    const secondsPrecision = minutes ? 0 : 2;

    if (days || hours || seconds || minutes) {
      return (
        (years ? years + "y " : "") +
        (days ? days + "d " : "") +
        (hours ? hours + "h " : "") +
        (minutes ? minutes + "m " : "") +
        Number.parseFloat(seconds + "").toFixed(secondsPrecision) +
        "s"
      );
    }

    return "< 1s";
  }
}

@Pipe({
  name: "filter",
  pure: false,
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], term): any {
    if (Array.isArray(items)) {
      return term
        ? items.filter((item) => {
          if (typeof item === "string" || item instanceof String) {
            return item.indexOf(term) !== -1;
          } else if (!isNaN(item)) {
            return item === term;
          } else {
            return JSON.stringify(item).indexOf(term) !== -1;
          }
        })
        : items;
    } else {
      return items;
    }
  }
}


@Pipe({ name: 'truncateString', standalone: true })
export class TruncateStringPipe implements PipeTransform {

  transform(value: string, limit: number): any {
    return value?.length < limit
      ? value
      : value?.slice(0, limit) + '...';
  }
}

@Pipe({ name: 'propetiesToText', standalone: true })
export class PropertiesToText implements PipeTransform {
  transform(value: any): any {
    let result: string;
    if (value.includes("_")) {
      result = value.replaceAll("_", " ");
    } else if (value.includes("-")) {
      result = value.replaceAll("-", " ");
    } else {
      result = value;
    }
    return result;
  }
}




@Pipe({
  name: "sortBy",

  standalone: true,
})
export class SortByPipe implements PipeTransform {
  transform(items: any[], sortedBy: string): any {
    return items.sort((a, b) => {
      return b[sortedBy] - a[sortedBy];
    });
  }
}

@Pipe({ name: 'decimalNumber', standalone: true })
export class DecimalNumberPipe implements PipeTransform {

  transform(value: string, limit: number = 2): any {
    if (value) {

      return parseFloat(value).toFixed(limit);
    }
  }
}


enum DisplayMode {
  Wide = 'Wide',
  Narrow = 'Narrow',
  Mobile = 'Mobile'
}


@Injectable({
  providedIn: "root",
})
export class SendRequestService {


  apiRoot: string;
  authorization: string;
  localInMemoryCache: Map<string, any>;
  pageMessages: string;
  cache: any;
  tables: Array<any>;
  TIMESTAMP_FORMAT: string = "yyyy-MM-dd HH:mm:ss";
  DATE_FORMAT: string = "yyyy-MM-dd";
  private _drawerOpen: boolean = false;
  mixpanelInitized: any;
  _navbar_data: any;

  private selectedApp: BehaviorSubject<any> = new BehaviorSubject({});

  drawerVisiblity: boolean = false;

  _recent_events: any[] = [];

  constructor(
    private location: Location,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private dateFormatter: DatePipe,
    private numberFormatter: DecimalPipe,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {
    this.apiRoot = environment.apiURL;
    if (!this.apiRoot.endsWith("/")) {
      this.apiRoot = this.apiRoot + "/";
    }
    // let authorization = this.cookieService.get("Authorization");
    let authorization = this.getAuthorizationToken();


    if (authorization) {
      this.authorization = authorization;
    }
    if (!this.authorization) {
      this.openLoginScreenIfRequired();
    }

    this.localInMemoryCache = new Map<string, any>();

    this.cache = {};
  }

  get drawerOpen() {
    return this._drawerOpen;
  }

  set drawerOpen(value) {
    this._drawerOpen = value;
  }

  get recent_events() {
    if (!this._recent_events) {

      try {
        const events_raw = localStorage.getItem("recent_events");
        this._recent_events = JSON.parse(events_raw);
      } catch (err) {
        this._recent_events = [];
      }

    }
    return this._recent_events;
  }

  save_recent_events_local_storage() {
    if (this._recent_events) {
      try {
        const threshold = new Date().getTime() - 3600000;
        const data = this._recent_events.filter(r => r.ts && r.ts > threshold);
        if (data) {
          localStorage.setItem("recent_events", JSON.stringify(this._recent_events));
        }
      } catch (err) { }
    }
  }



  public SHARING_MODES = ["Public", "Private", "None"];


  setLocalInMemoryCache(key: string, value: any) {
    this.localInMemoryCache.set(key, value);
  }
  getLocalInMemoryCache(key: string) {
    return this.localInMemoryCache.get(key);
  }
  removeLocalInMemoryCache(key: string) {
    this.localInMemoryCache.delete(key);
  }

  setAuthorization(token: string) {
    if (!token.startsWith("Bearer ")) {
      token = `Bearer ${token}`;
    }
    this.authorization = token;
    this.cookieService.set("Authorization", token, 1, "/");
    localStorage.setItem("Authorization", token);
  }

  setCurrentUser(userInfo: any) {
    localStorage.setItem("UserId", userInfo.id);
    localStorage.setItem("UserGuid", userInfo.guid);
    localStorage.setItem("UserInfo", JSON.stringify(userInfo));

    if (userInfo.home_page) {
      localStorage.setItem("UserHomePage", userInfo.home_page || '/landing');
    }
  }
  getCurrentUser() {
    if (!this.isLoggedIn()) {
      localStorage.removeItem("UserInfo");
      return;
    }
    const navbar = this.getNavbarResponseCache();
    if (navbar?.user) {
      return navbar.user;
    }
    try {
      const s = localStorage.getItem("UserInfo");
      return JSON.parse(s);
    } catch (e) { }
  }

  getGotoLink() {
    return this.cookieService.get("goto");
  }

  requireLoginPath(path) {
    return !(
      path.startsWith("/signup") ||
      path.startsWith("/home") ||
      path.startsWith("/login") ||
      path.startsWith("/oauth2callback") ||
      path.startsWith("/oauth2-login-code/google") ||
      path.startsWith("/verify-email") ||
      path.startsWith("/change-password") ||
      path.startsWith("/contact-us") ||
      path.startsWith("/ping") ||
      path.startsWith("/auth/lo") ||
      path.startsWith("/learning")
    );
  }

  requiredAuthAPIHeaders(path: string) {
    return !(
      path.startsWith("/auth/trigger-email-verification") ||
      path.startsWith("/auth/verify-email") ||
      path.startsWith("/auth/change-password") ||
      path.startsWith("/auth/email-password-reset-token") ||
      path.startsWith("/auth/signup") ||
      path.startsWith("/auth/lo") ||
      path.startsWith("/auth/verify-login-otp") ||
      path.startsWith("/auth/oauth2/") ||
      path.startsWith("/ping")
    );
  }

  openLoginScreenIfRequired() {
    const path = window.location.pathname;
    if (this.requireLoginPath(path)) {
      if (window.location.pathname !== "/login") {
        // this.router.navigate(["/login"], {
        //   queryParams: { go: window.location.pathname },
        // });
        this.router.navigateByUrl("/home");
      } else {
        this.router.navigateByUrl("/login");
      }
    }
  }

  getHeaders() {
    const token = this.getAuthorizationToken();
    if (!token) {
      this.openLoginScreenIfRequired();
      return;
    }
    const header = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    let headers = new HttpHeaders(header);
    return headers;
  }

  getNoAuthHeaders() {
    const header = {
      "Content-Type": "application/json",
    };
    let headers = new HttpHeaders(header);
    return headers;
  }

  getHeadersWithGuestToken() {
    const headers = {
      "Content-Type": "application/json",
      "X-GuestToken": localStorage.getItem("X-GuestToken") || "",
    };
    return new HttpHeaders(headers);
  }

  isLoggedIn(): boolean {
    this.authorization = this.getAuthorizationToken();
    return (
      this.authorization != undefined &&
      this.authorization.startsWith("Bearer ")
    );
  }

  getAuthorizationToken() {
    return localStorage.getItem("Authorization");
  }

  public filterChange(requestURL, param, postParams, requestMethod) {
    this.apiRoot = requestURL;
    var encodeParams = [];
    if (param != "") {
      param.forEach((value: any, key: any) => {
        var temp = [];
        temp = value.split("=");
        var tempValue = temp[1];
        if (tempValue == undefined || tempValue == "") {
        } else {
          tempValue = tempValue.replace("AND_SIGN", "&");
        }
        encodeParams.push(temp[0] + "=" + encodeURIComponent(tempValue));
      });
      var parameters = encodeParams.join("&");
    }
    if (requestMethod == "post") {
      let promise = new Promise((resolve, reject) => {
        const headers = new HttpHeaders().set(
          "X-Requested-With",
          "XMLHttpRequest"
        );
        var parameters = encodeParams.join("&");
        var apiURL = this.apiRoot + "?" + parameters;
        this.http
          .post(apiURL, { headers })
          .toPromise()
          .then(
            (res: any) => {
              resolve(res);
            },
            (msg) => {
              reject(msg);
            }
          );
      });
      return promise;
    } else {
      let promise = new Promise((resolve, reject) => {
        var apiURL = this.apiRoot;
        this.http
          .get(apiURL)
          .toPromise()
          .then(
            (res: any) => {
              if (typeof res == "string" && res.match(/error/)) {
                alert("got error " + res);
              }
              resolve(res);
            },
            (msg) => {
              reject(msg);
            }
          );
      });
      return promise;
    }
  }

  sendRequestCommonFunction(requestURL, param, postParams, requestMethod) {
    let newheader = this.getHeaders();
    var apiRoot = this.apiRoot + requestURL;

    if (requestMethod == "get") {
      return this.http.get(apiRoot, { headers: newheader });
    } else if (requestMethod == "post") {
      return this.http.post(apiRoot, postParams, { headers: newheader });
    } else if (requestMethod == "delete") {
      return this.http.delete(apiRoot, { headers: newheader });
    }
  }

  getRequest(path: string, params: HttpParams = undefined): Observable<any> {
    return this.makeHttpRequest("GET", path, params, undefined);
  }

  postRequest(
    path: string,
    body: any = undefined,
    urlParams: HttpParams = undefined
  ): Observable<any> {
    return this.makeHttpRequest("POST", path, urlParams, body);
  }

  putRequest(
    path: string,
    body: any = undefined,
    urlParams: HttpParams = undefined
  ): Observable<any> {
    return this.makeHttpRequest("PUT", path, urlParams, body);
  }

  patchRequest(
    path: string,
    body: any = undefined,
    urlParams: HttpParams = undefined
  ): Observable<any> {
    return this.makeHttpRequest("PATCH", path, urlParams, body);
  }

  deleteRequest(
    path: string,
    urlParams: HttpParams = undefined,
    body: any = undefined
  ): Observable<any> {
    return this.makeHttpRequest("DELETE", path, urlParams, body);
  }

  replaceEmptyStrings(body: any) {
    if (this.isObject(body)) {
      for (let key in body) {
        if (body[key] === "") {
          body[key] = null;
        } else if (this.isObject(body[key])) {
          this.replaceEmptyStrings(body[key]);
        }
      }
    }
  }

  makeHttpRequest(
    method: string,
    path: string,
    params: any,
    body: any
  ): Observable<any> {
    const authToken = this.getAuthorizationToken();
    const requireAuth = this.requiredAuthAPIHeaders(path);
    if (!authToken && requireAuth) {
      const next = this.location.path();
      this.router.navigate(['/login'], {
        queryParams: { go: next }
      });
      // throw Error(
      //   `Requires authentication to access ${path}, but no authentication details are found.`
      // );
    }
    let url = this.getUrl(path);
    if (url.endsWith("/")) {
      url = url.substring(0, url.length - 1);
    }
    if (body) {
      const auditFields = [
        "created_by",
        "created_by_id",
        "last_modified_by",
        "last_modified_by_id",
        "created_on",
        "last_modified_on",
        "owner",
        "owner_id",
      ];
      auditFields.forEach((k) => {
        if (body[k]) {
          delete body[k];
        }
      });

      this.replaceEmptyStrings(body);
    }
    const headers = requireAuth ? this.getHeaders() : this.getNoAuthHeaders();
    // const headers = this.getNoAuthHeaders();
    const observable = new Observable((observer) => {
      const res = this.http.request(method, url, {
        body: body || {},
        params: params || {},
        headers: headers,
      });
      res.subscribe(
        (successResponse) => {
          observer.next(successResponse);
          observer.complete();
        },
        (errorResponse: any) => {
          if (errorResponse.status === 403) {
            const data = errorResponse;
            const next = this.location.path();
            this.logout(next);
            this.router.navigateByUrl('/login');
          } else {
            errorResponse.__error = {
              statusCode: errorResponse.status,
              messsage: errorResponse.message,
            };
            observer.next(errorResponse);
          }
          observer.complete();
        }
      );
    });
    return observable;
  }

  makeHttpRequestUnAuthenticated(
    method: string,
    path: string,
    params: any = undefined,
    body: any = undefined,
    headers: HttpHeaders = undefined
  ): Observable<any> {
    const url = this.getUrl(path);
    if (!headers) {
      headers = this.getNoAuthHeaders();
    }

    const observable = new Observable((observer) => {
      const res = this.http.request(method, url, {
        body: body || {},
        params: params || {},
        headers: headers,
        withCredentials: false,
        transferCache: false
      });
      res.subscribe(
        (successResponse) => {
          observer.next(successResponse);
          observer.complete();
        },
        (errorResponse: any) => {
          observer.next(errorResponse);
          observer.complete();
        }
      );
    });
    return observable;
  }


  postRequestForm(path: string, body: any, urlParams?: HttpParams) {
    const header = {
      Authorization: this.authorization,
    };
    let headers = new HttpHeaders(header);

    let url = this.getUrl(path);
    let options = {
      headers: headers,
    };
    if (urlParams != undefined) {
      options["params"] = urlParams;
    }

    let observable = new Observable((observer) => {
      this.http.post(url, body, options).subscribe(
        (response: any) => {
          observer.next(response);
          observer.complete();
        },
        (errorResponse) => {
          if (
            errorResponse.status == 403 &&
            errorResponse.error.status === "SESSION_EXPIRED"
          ) {
            const next = this.location.path();
            this.logout(next);
          } else if (errorResponse.status == 0) {
            this.pageMessages =
              "The API server is currently unavailable. Try again or contact system administrator";
          } else {
            observer.next(errorResponse);
            observer.complete();
          }
        }
      );
    });
    return observable;
  }

  recordRecentEvent(object_type: string, object_id: string, name: string = undefined, path: string = undefined) {
    if ((object_type && object_id) || path) {


      let body = {
        object_id: object_id,
        object_type: object_type,
        path: path,
        name: name,
        ts: new Date().getTime()
      };

      for (let rec of this.recent_events) {
        if ((rec.object_id === object_id && rec.object_type === object_type) || rec.path === path) {
          return;
        }
      }
      this.recent_events.push(body);


      setTimeout(() => {
        if (this.isLoggedIn()) {
          const data = { ...body };
          delete data['ts'];
          this.postRequest(`/users/recent-events`, data).subscribe((response) => {
            const error = this.checkError(response);
            if (error) {
              console.error("Failed to record recent event", error);
            }
            this.save_recent_events_local_storage();
            // this.getRequest(`/users/recent-events`).subscribe();
          });
        }
      }, 1000);
    }
  }

  saveUserPreference(key: string, body: any) {
    key = encodeURIComponent(key);
    this.postRequest(`/users/preferences/${key}`, body).subscribe();
  }

  getUserPreference(key: string) {
    key = encodeURIComponent(key);
    return this.getRequest(`/users/preferences/${key}`);
  }

  logoutNoRedirect() {

    const clearCache = () => {

      localStorage.clear();
      this.cookieService.delete("Authorization", "/");
      this.authorization = undefined;
    };
    const token = this.getAuthorizationToken();
    if (token) {
      const url = this.getUrl("/users/logout");
      this.http.request("GET", url, {
        headers: new HttpHeaders().append("Authorization", token),
      }).subscribe();
    }
    clearCache();
  }

  logout(next: string = undefined) {
    this.logoutNoRedirect();

    if (
      next &&
      !(next.startsWith("/login") || next.startsWith("/reset-password") || next.startsWith("/home"))
    ) {
      let loginUrl = "/login";
      loginUrl = loginUrl + "?go=" + encodeURIComponent(next);
      this.router.navigateByUrl(loginUrl);
    }
  }

  clearGlobalCache() {
    let url = "/schema/clear-cache";
    return this.getRequest(url);
  }

  signUp(obj) {
    let apiURL = this.apiRoot + "users";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(apiURL, obj, {
      headers: headers,
      observe: "response",
      responseType: "json",
      withCredentials: false,
    });
  }

  getUrl(path: string) {
    if (path.startsWith("/api/")) {
      return path;
    }
    if (path.startsWith("/")) {
      path = path.substring(1);
    }
    return this.apiRoot + path;
  }

  loadTableSchema(tableName: string) {
    const keyName: string = "__schema__" + tableName;
    let cachedResponse = localStorage.getItem(keyName);
    let observable: Observable<any> = null;
    if (cachedResponse && environment.enableSchemaCaching) {
      cachedResponse = JSON.parse(cachedResponse);
      observable = new Observable((observer) => {
        observer.next(cachedResponse);
        observer.complete();
      });
    } else {
      observable = new Observable((observer) => {
        let path = "/schema/describe-table/" + tableName;
        this.getRequest(path).subscribe((response: any) => {
          if (!response.__error__) {
            localStorage.setItem(keyName, JSON.stringify(response));
          }
          observer.next(response);
          observer.complete();
        });
      });
    }
    return observable;
  }

  saveRecord(tableName: string, record: any) {
    let url = "/schema/upsert/" + tableName;
    let recordClone = Object.assign({}, record);
    delete recordClone["created_on"];
    delete recordClone["created_by_id"];
    let body = {
      records: [recordClone],
    };
    let observable: Observable<any> = new Observable((observer) => {
      this.postRequest(url, body).subscribe((response: any) => {
        if (
          !this.checkError(response) &&
          Array.isArray(response.records) &&
          response.records.length > 0
        ) {
          observer.next(response.records[0]);
        } else {
          observer.next(response);
          observer.complete();
        }
      });
    });
    return observable;
  }

  findColumnByName(table: any, name: string) {
    let found = undefined;
    if (
      table != undefined &&
      table.columns != undefined &&
      Array.isArray(table.columns)
    ) {
      table.columns.forEach((column) => {
        if (column.name == name) {
          found = column;
        }
      });
    }
    if (found == undefined) {
      console.warn(name + " is not found among the columns in " + table.name);
    }
    return found;
  }

  resolveTimestampFields(tableSchema: any, records: Array<any>) {
    if (tableSchema == undefined || tableSchema.columns == undefined) {
      return;
    }
    let timestampFields = [];
    tableSchema.columns.forEach((column) => {
      if (
        column.type == "Timestamp" &&
        timestampFields.indexOf(column.name) < 0
      ) {
        timestampFields.push(column.name);
      }
    });
    records.forEach((record) => {
      for (let key in record) {
        let value = record[key];
        if (
          timestampFields.indexOf(key) >= 0 &&
          value != undefined &&
          value.length > 0
        ) {
          let date = new Date(Date.parse(value));
          //record[key] = date.toISOString().slice(0, 16);
          record[key] = new Date(Date.parse(value.replace(" ", "T")))
            .toISOString()
            .slice(0, 16);
        }
      }
    });

    return records;
  }

  getDataForListView(tableName: string, viewName: string, page?: string) {
    const observable = new Observable((observer) => {
      let url = "/schema/filter/" + tableName + "/" + viewName;
      if (page) {
        url = url + "?page=" + page;
      }
      this.getRequest(url).subscribe((response: any) => {
        if (response && Array.isArray(response.records)) {
          response.records.forEach((record) => {
            if (!record.name) {
              record.name = record.id;
            }
          });
        }
        this.loadTableSchema(tableName).subscribe((tableSchema) => {
          if (response && response.records) {
            this.resolveTimestampFields(tableSchema, response.records);
          }
          observer.next(response);
          observer.complete();
        });
      });
    });
    return observable;
  }

  getRecordsByIds(tableName: string, recordIds: string[]): Observable<any> {
    let url = "/schema/lookup-by-ids/" + tableName;
    return this.postRequest(url, { ids: recordIds });
  }

  getRecordById(tableName: string, id: string): Observable<any> {
    let url = "/schema/lookup-by-id/" + tableName + "/" + id;
    return this.getRequest(url);
  }

  getRecordByParent(tableName: string, parent: string) {
    let url = "/schema/lookup-by-partition-key/" + tableName + "/" + parent;
    return this.getRequest(url);
  }

  getUserInfo(userId: string, params = undefined) {
    if (!this.isValidUUID(userId)) {
      throw "Invalid UserId: " + userId;
    }
    return this.getRequest("/users/" + userId, params);
  }

  getCurrentUserId(): string {
    const user = this.getCurrentUser();
    if (user) {
      return user.guid || user.id;
    }

  }

  getTables(discardCache: boolean = false) {
    const observable = new Observable<Array<any>>((observer) => {
      const url = "/schema/tables";
      this.getRequest(url).subscribe((response: any) => {
        if (!this.checkError(response)) {
          const tables = response.records;
          //this.putCacheItem("collections", "tables", tables);
          tables.forEach((table) => {
            table.__routerLink = "/tables/" + table.name;
          });
          tables.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
          observer.next(tables);
          observer.complete();
        }
      });
    });
    return observable;
  }

  getColumns(discardCache: boolean = false) {
    const observable = new Observable<Array<any>>((observer) => {
      const cachedData = this.getCacheItem("collections", "columns");
      if (discardCache || cachedData == undefined) {
        const url = "/schema/columns";
        this.getRequest(url).subscribe((response: any) => {
          const columns = response.records;
          this.putCacheItem("collections", "columns", columns);
          observer.next(columns);
          observer.complete();
        });
      } else {
        observer.next(cachedData);
        observer.complete();
      }
    });
    return observable;
  }

  getCacheItem(cacheName: string, key: string) {
    if (this.cache && this.cache[cacheName] && this.cache[cacheName][key]) {
      return this.clone(this.cache[cacheName][key]);
    } else {
      const obj = localStorage.getItem(cacheName + "." + key);
      if (obj != undefined) {
        try {
          return JSON.parse(obj);
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }

  removeCacheItem(cacheName: string, key: string) {
    if (this.cache && this.cache[cacheName] && this.cache[cacheName][key]) {
      delete this.cache[cacheName][key];
    }
    localStorage.removeItem(cacheName + "." + key);
  }

  getCacheItems(cacheName: string) {
    if (this.cache) {
      const collection = this.cache[cacheName];
      if (collection) {
        const records = [];
        for (let key in collection) {
          records.push(collection[key]);
        }
        return this.clone(records);
      }
    }
  }

  putCacheItem(cacheName: string, key: string, value: any) {
    if (this.cache == undefined) {
      this.cache = {};
    }
    if (this.cache && !this.cache[cacheName]) {
      this.cache[cacheName] = {};
    }
    if (this.cache && this.cache[cacheName]) {
      this.cache[cacheName][key] = this.clone(value);
    }
    const valueString = JSON.stringify(value);
    try {
      localStorage.setItem(cacheName + "." + key, valueString);
    } catch (e) {
      console.log("Error while putting data to cache: ", value);
    }
  }

  getTableByName(name: string, cached: boolean = true) {
    const observable = new Observable<any>((observer) => {
      const url = "/schema/describe-table/" + name;
      this.getRequest(url).subscribe((tableEntity: any) => {
        this.processTableResponse(tableEntity);
        observer.next(tableEntity);
        observer.complete();
      });
    });
    return observable;
  }

  getTableById(tableId: number, cached: boolean = true) {
    const observable = new Observable<any>((observer) => {
      const url = "/schema/tables/" + tableId;
      this.getRequest(url).subscribe((tableEntity: any) => {
        this.processTableResponse(tableEntity);
        observer.next(tableEntity);
        observer.complete();
      });
    });
    return observable;
  }

  processTableResponse(tableEntity: any) {
    if (!this.checkError(tableEntity)) {
      tableEntity.columnByName = {};
      if (Array.isArray(tableEntity.columns)) {
        const tbl_lite = this.clone(tableEntity);
        tbl_lite.columns = undefined;
        tbl_lite.created_by_id = undefined;
        tbl_lite.created_on = undefined;
        tbl_lite.last_modified_by_id = undefined;
        tbl_lite.last_modified_on = undefined;

        tableEntity.columns.forEach((col) => {
          tableEntity.columnByName[col.name] = col;
          col.table = tbl_lite;
        });
      }
      if (Array.isArray(tableEntity.columns)) {
        tableEntity.columns.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
    }
  }

  getColumn(tableName: string, columnName: string) {
    const url = "/schema/describe-column/" + tableName + "/" + columnName;
    return this.getRequest(url);
  }

  getColumnById(tableId: number, columnId: number) {
    const url = `/schema/tables/${tableId}/columns/${columnId}`;
    return this.getRequest(url);
  }

  loadColumnByName(tableName: string, columnName: string): Observable<any> {
    const observable = new Observable<any>((observer) => {
      this.getTableByName(tableName, false).subscribe((table) => {
        if (!this.checkError(table)) {
          let columnEntity: any;
          table.columns.forEach((col) => {
            if (col.name == columnName) {
              columnEntity = col;
            }
          });
          if (columnEntity) {
            observer.next(columnEntity);
            observer.complete();
          }
        } else {
          observer.next(table);
          observer.complete();
        }
      });
    });
    return observable;
  }

  clone(obj: any): any {
    if (obj) {
      var cache = [];
      const json = JSON.stringify(obj, function (key, value) {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // Circular reference found, discard key
            return;
          }
          // Store value in our collection
          cache.push(value);
        }
        return value;
      });
      return JSON.parse(json);
    }
  }

  formatNumber(formatter, format) {
    const f = function (params) {
      return formatter.transform(params.value, format);
    };
    return f;
  }

  formatDate(value: any) {
    this.dateFormatter.transform(value, "yyyy-MM-dd");
  }

  formatTimestamp(value: any) {
    this.dateFormatter.transform(value, "yyyy-MM-dd HH:mm:ss");
  }



  agGridColumn(column: any) {
    const columnDef: any = {
      headerName: column.label,
      field: column.name,
      resizable: true,
      sortable: true,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      __metadata__: column,
    };

    // if (column.columnEntity) {
    //     if (column.columnEntity && column.columnEntity.type == "Lookup") {
    //         columnDef.field = column.name + "__r.name";
    //     }
    // }
    // if (column.type == "Lookup") {
    //     columnDef.field = column.name + "__r.name";
    // }

    if (column.label) {
      columnDef.headerName = column.label;
    } else if (!column.label) {
      columnDef.headerName = column.name;
    }

    if (column.hasOwnProperty("suppressMovable")) {
      columnDef.suppressMovable = column.suppressMovable;
    }

    if (column.maxWidth) {
      columnDef.maxWidth = column.maxWidth;
    }

    if (column.cellRendererParams) {
      columnDef["cellRenderer"] = column.cellRendererFramework;
      columnDef["cellRendererParams"] = column.cellRendererParams;
    }

    if (column.cellRendererFramework) {
      columnDef["cellRenderer"] = column.cellRendererFramework;
    }

    if (column.template) {
      // columnDef["cellRenderer"] = TemplateRendererComponent;
      columnDef["cellRendererParams"] = {
        ngTemplate: column.template,
      };
    }

    if (column.cellClass) {
      columnDef["cellClass"] = column.cellClass;
    }
    if (column.sort) {
      columnDef["sort"] = column.sort;
    }
    if (column.hasOwnProperty("rowGroup")) {
      columnDef["rowGroup"] = column.rowGroup;
      columnDef["hide"] = true;
    }

    if (["Integer", "Bigint", "Float", "Double"].includes(column.type)) {
      columnDef["filter"] = "agNumberColumnFilter";
      columnDef["type"] = "numericColumn";
      if (["Integer", "Bigint", "Long"].includes(column.type)) {
        columnDef["valueFormatter"] = this.formatNumber(
          this.numberFormatter,
          "1.0"
        );
      } else if (["Float", "Double"].includes(column.type)) {
        columnDef["valueFormatter"] = this.formatNumber(
          this.numberFormatter,
          "1.2-3"
        );
      }
      columnDef["width"] = 100;
    }

    if (
      ["Text", "Textarea", "Lookup", "UUID", "Picklist"].includes(column.type)
    ) {
      columnDef["filter"] = "agTextColumnFilter";
      columnDef["width"] = 120;
    }

    if (["Date", "Timestamp"].includes(column.type)) {
      columnDef["filter"] = "agDateColumnFilter";

      const dateFomatter = this.dateFormatter;
      if ("Date" == column.type) {
        columnDef["width"] = 100;
        columnDef["valueFormatter"] = function (e) {
          let value = e.value;
          if (value instanceof Date) {
            value = value.toISOString();
          }
          return dateFomatter.transform(value, "yyyy-MM-dd");
        };
      } else {
        columnDef["width"] = 160;
        columnDef["valueFormatter"] = function (e) {
          let value = e.value;
          if (value instanceof Date) {
            value = value.toISOString();
          }
          return dateFomatter.transform(value, "yyyy-MM-dd HH:mm:ss");
        };
      }
    }

    if ("Object" == column.type) {
      columnDef["valueGetter"] = function (params) {
        try {
          if (params.data) {
            const type = typeof params.data;
            if (type === "object") {
              return JSON.stringify(params.data[column.name]);
            }
          }
        } catch (err) { }
        return params.data[column.name];
      };
    }

    // if (column.type === 'Lookup') {
    //     columnDef["valueGetter"] = function (params) {
    //         try {
    //             if (params.data) {
    //                 const type = typeof params.data;
    //                 if (type === "object") {
    //                     return params.data[column.name + "__r"] || params.data[column.name];
    //                 }
    //             }
    //         } catch (err) { }
    //         return params.data[column.name];
    //     };
    // }

    if ("function" === typeof column.valueGetter) {
      columnDef["valueGetter"] = column.valueGetter;
    }

    if ("function" === typeof column.valueFormatter) {
      columnDef["valueFormatter"] = column.valueFormatter;
    }
    if ("function" === typeof column.cellRenderer) {
      columnDef["cellRenderer"] = column.cellRenderer;
    }
    if (column.width) {
      try {
        columnDef.width = parseInt(column.width);
      } catch (e) {
        console.warn("Width must be an integer: " + column.width);
      }
    }

    if (column.headerClass) {
      if (!columnDef.headerClass) {
        columnDef.headerClass = [];
      }
      columnDef.headerClass.push(column.headerClass);
    }
    if (column.type) {
      columnDef.headerTooltip = column.type;
    }

    return columnDef;
  }

  createAgGridColumns(names: Array<string>) {
    const columnDefs = [];
    for (let i in names) {
      columnDefs.push(this.agGridColumn({ name: names[i] }));
    }
    return columnDefs;
  }

  checkError(response: any, addition_info: any = undefined) {
    if (response === undefined || response == null) {
      if (addition_info) {
        console.log(addition_info);
      }
      return "Null response, usually caused by invalid id.";
    }

    if (response instanceof HttpErrorResponse && response.error != undefined) {
      if (addition_info) {
        console.log(addition_info);
      }
      if (response.status >= 500) {
        if (response.error?.message) {
          return response.error?.message;
        }
        return `[${response.status}] Internal server error.`;
      } else if (typeof response.error == "string") {
        return response.error;
      } else if (typeof response.error == "object") {
        if (Array.isArray(response.error.errors)) {
          const parts = [];
          for (let i in response.error.errors) {
            const error = response.error.errors[i];
            parts.push(error["field"] + ": " + error["message"]);
          }
          return parts.join("\n");
        } else if (response.status == 404) {
          return (
            "Invalid request URL: " + (response.error.path || response.url)
          );
        } else if (response.status == 0) {
          console.log("API is not reachable");
        } else {
          return (
            response.error.message ||
            response.error.status ||
            "Error occurred but no error message has been set"
          );
        }
      } else {
        const error = response.error;
        return error.message || "Unknown type of error";
      }
    } else {
      if (!response.__error) {
        return;
      }
      return response.message;
    }
  }

  getFields(records: Array<any>) {
    const keys = [];
    if (Array.isArray(records)) {
      for (let i = 0; i < records.length; ++i) {
        const record = records[i];
        if (record.constructor.name === "Object") {
          for (let key in record) {
            if (keys.indexOf(key) < 0) {
              keys.push(key);
            }
          }
        }
      }
    }
    keys.sort();
    return keys;
  }

  // isValidUUID(value: any) {
  //   //return value != undefined && typeof value === "string" && value.length == 36;
  //   return (
  //     value != undefined &&
  //     (this.isInteger(value) ||
  //       /^\d+$/.test(value) ||
  //       (typeof value === "string" && value.length == 36))
  //   );
  // }


  isValidUUID(value: any) {
    if (value) {
      if (this.isInteger(value)) {
        return true;
      }
      if (typeof value === "string") {
        if (/^\d+$/.test(value)) {
          return true;
        }
        return value.length == 36 || value.length == 26;
      }
    }
  }

  isRecordID(value: number) {
    //return value != undefined && typeof value === "string" && value.length == 36;
    // return value != undefined && value.length > 0;
    return value != undefined && value > 0;

  }

  toSnakeCase(value: string, lower: boolean = true) {
    let result = "";
    if (value) {
      if (lower) {
        value = value.toLocaleLowerCase();
      }
      result = value
        .replace(/[^A-Za-z0-9_\s]/g, "_")
        .replace(/([A-Z]+)/g, " $1")
        .replace(/\s+/g, " ")
        .trim()
        .replace(/\s/g, "_");
      if (/^\d/.test(result)) {
        result = "x" + result;
      }
      result = result.replace(/__/g, "_");
      if (result.startsWith("_")) {
        result = result.substring(1, result.length - 1);
      }
      return result;
    }
    return result;
  }

  makeCsvString(inputArray: Array<string>) {
    if (!Array.isArray(inputArray)) {
      return inputArray;
    }
    const values = [];
    for (let i = 0; i < inputArray.length; ++i) {
      let v = inputArray[i];
      if (v != undefined) {
        v = v.replace(/"/g, '"');
        if (v.indexOf(",") >= 0) {
          v = '"' + v + '"';
        }
        values.push(v);
      }
    }
    return values.join(",");
  }

  csvToArray(strData: string, strDelimiter: string = ",") {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ",";

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
      // Delimiters.
      "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
      "gi"
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      var strMatchedDelimiter = arrMatches[1];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([]);
      }

      var strMatchedValue;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3];
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
  }

  getListViewById(listViewLayoutId: string) {
    const observable = new Observable<any>((observer) => {
      const path = "/page-layout/list-view-layout-by-id/" + listViewLayoutId;
      this.getRequest(path).subscribe((response: any) => {
        const error = this.checkError(response);
        if (error) {
          observer.next(response);
        } else {
          if (
            response &&
            response.config &&
            Array.isArray(response.config.columns) &&
            response.table_entity &&
            Array.isArray(response.table_entity.columns)
          ) {
            const columnByName = {};
            response.table_entity.columns.forEach((col) => {
              columnByName[col.name] = col;
            });
            response.config.columns.forEach((col) => {
              const entity = columnByName[col.name];
              $.extend(col, entity);
            });
          }
          observer.next(response);
        }
      });
    });
    return observable;
  }

  getListViews(tableName: string = undefined, params: HttpParams = undefined) {
    let path = "/page-layout/get-all-list-views";
    if (tableName) {
      path = "/page-layout/list-view-layout-by-table/" + tableName;
    }
    return this.getRequest(path, params);
  }

  closeObservables(obseravables: any[]) {
    if (!obseravables) {
      return;
    }
    obseravables.forEach((res) => {
      try {
        res.unsubscribe();
      } catch (error) { }
    });
  }

  getDataFromListView(
    listViewId: string,
    params: HttpParams = undefined
  ): Observable<any> {
    const observable = new Observable<any>((observer) => {
      this.getListViewById(listViewId).subscribe((listViewEntity) => {
        const error = this.checkError(listViewEntity);
        if (error) {
          observer.next(listViewEntity);
          observer.complete();
          return;
        }
        const dateColumnByName = {};
        listViewEntity.config.columns.forEach((col) => {
          if (col.type == "Date" || col.type == "Timestamp") {
            dateColumnByName[col.name] = col;
          }
        });
        const path = "/schema/get-list-view-data/" + listViewId;
        this.getRequest(path, params).subscribe((response: any) => {
          const error = this.checkError(response);
          if (error) {
            observer.next(response);
            return;
          }
          if (Array.isArray(response.records)) {
            response.records.forEach((record) => {
              for (let colName in dateColumnByName) {
                if (record[colName] != null) {
                  record[colName] = new Date(Date.parse(record[colName]));
                }
              }
            });
          }
          observer.next(response);
          observer.complete();
        });
      });
    });
    return observable;
  }

  getReports(tableName: string = undefined): Observable<any> {
    const path = "/report";
    return this.getRequest(path);
  }
  getReportInfo(reportId: string): Observable<any> {
    const observable = new Observable<any>((observer) => {
      const path = "/report/" + reportId;
      this.getRequest(path).subscribe((response: any) => {
        if (this.checkError(response)) {
          observer.next(response);
          observer.complete();
          return;
        }
        if (Array.isArray(response.output_schema)) {
          const typeMapping = {
            StringType: "Text",
            IntegerType: "Integer",
            LongType: "Bigint",
            FloatType: "Float",
            DoubleType: "Double",
            DateType: "Date",
            TimestampType: "Timestamp",
          };
          response.output_schema.forEach((col) => {
            if (typeMapping[col.type]) {
              col.type = typeMapping[col.type];
            }
          });
        }
        observer.next(response);
        observer.complete();
      });
    });
    return observable;
  }

  map(array: any[], callable: CallableFunction) {
    if (Array.isArray(array)) {
      const result = [];
      array.forEach((item) => {
        result.push(callable(item));
      });
      return result;
    }
  }

  removeArrayElement(list: Array<any>, index: number, inline: boolean = false) {
    if (Array.isArray(list)) {
      const result = [];
      for (let i = 0; i < list.length; ++i) {
        if (i != index) {
          result.push(list[i]);
        }
      }
      if (inline) {
        while (list.length > 0) {
          list.pop();
        }
        result.forEach((item) => list.push(item));
      }
      return result;
    } else {
      console.error("removeArrayElement: invalid input list provided");
    }
  }

  clearArray(array: Array<any>) {
    if (Array.isArray(array)) {
      while (array.length > 0) {
        array.pop();
      }
    }
  }

  mergeArray(source: Array<any>, target: Array<any>) {
    if (Array.isArray(source) && Array.isArray(target)) {
      for (var i = 0; i < source.length; ++i) {
        target.push(source[i]);
      }
    }
  }

  filter(array: Array<any>, callable: CallableFunction) {
    if (!Array.isArray(array)) {
      return;
    }
    const result = [];
    for (let i = 0; i < array.length; ++i) {
      if (callable(array[i])) {
        result.push(array[i]);
      }
    }
    return result;
  }

  getViews(useCached: boolean = true) {
    const observable = new Observable<any[]>((observer) => {
      const path = "/views/get-all-views";
      this.getRequest(path).subscribe((response) => {
        const error = this.checkError(response);
        if (error || !Array.isArray(response.records)) {
          observer.next(response);
          return;
        }
        observer.next(response.records);
        observer.complete();
      });
    });
    return observable;
  }

  dict2list(doc: any) {
    const result = [];
    if (doc) {
      for (var key in doc) {
        result.push({ key: key, value: doc[key] });
      }
    }
    return result;
  }

  autoResizeGridColumns(gridColumnApi: any) {
    setTimeout(() => {
      if (gridColumnApi && Array.isArray(gridColumnApi.getAllColumns())) {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds);
      }
    }, 50);
  }

  parseDate(date: any) {
    if (this.isString(date)) {
      return new Date(Date.parse(date));
    } else if (this.isInteger(date)) {
      return new Date(date);
    }
    return date;
  }

  loadColumnFunctions() {
    const observable = new Observable<any>((observer) => {
      const path = "/report/get-column-functions";
      this.getRequest(path).subscribe((columnFunctions) => {
        if (!this.checkError(columnFunctions)) {
          const uuidTypes = ["UUID", "Lookup"];
          const stringTypes = ["Text", "Textarea", "Picklist", "String"];
          const numericTypes = ["Integer", "Bigint", "Float", "Double"];
          const temporalTypes = [
            "Date",
            "Timestamp",
            "DateType",
            "TimestampType",
          ];
          columnFunctions.getAggregateFunctionsByType = function (
            columnType: string
          ) {
            let options: any[];
            if (numericTypes.indexOf(columnType) >= 0) {
              options = this.columnFunctions["numeric_aggregate_functions"];
            } else if (temporalTypes.indexOf(columnType) >= 0) {
              options = this.columnFunctions["date_aggregate_functions"];
            } else if (stringTypes.indexOf(columnType) >= 0) {
              options = this.columnFunctions["string_aggregate_functions"];
            } else if (columnType == "Boolean") {
              options = this.columnFunctions["boolean_aggregate_functions"];
            } else if (uuidTypes.indexOf(columnType) >= 0) {
              options = this.columnFunctions["uuid_aggregate_functions"];
            } else {
              console.error("Column type is not supprted - " + columnType);
            }
            return options;
          };

          columnFunctions.getFilterFunctionsByTypeAndCondition = function (
            columnType: string,
            condition: string
          ) {
            let cond = undefined;
            const options =
              columnFunctions.getFilterFunctionsByType(columnType);
            options.forEach((op) => {
              if (op.condition == condition) {
                cond = op;
              }
            });
            return cond;
          };

          columnFunctions.getFilterFunctionsByType = function (
            columnType: string
          ) {
            let options;
            if (numericTypes.indexOf(columnType) >= 0) {
              options = columnFunctions["numeric_filter_functions"];
            } else if (temporalTypes.indexOf(columnType) >= 0) {
              options = columnFunctions["date_filter_functions"];
            } else if (stringTypes.indexOf(columnType) >= 0) {
              options = columnFunctions["string_filter_functions"];
            } else if (columnType == "Boolean") {
              options = columnFunctions["boolean_filter_functions"];
            } else if (uuidTypes.indexOf(columnType) >= 0) {
              options = columnFunctions["uuid_filter_functions"];
            } else {
              console.error("Column type is not supprted - " + columnType);
            }
            return options;
          };
        }
        observer.next(columnFunctions);
      });
    });
    return observable;
  }

  copyToClipboard(text) {
    var input = document.createElement("input");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
  }

  getTableRecords(
    tableName: string,
    limit: number = undefined
  ): Observable<any> {
    const observer = new Observable<any>((observer) => {
      const path = "/schema/select/" + tableName;
      this.getRequest(path).subscribe((response) => {
        observer.next(response);
        observer.complete();
      });
    });
    return observer;
  }

  autoSizeColumns(gridColumnApi, delay: number = 100) {
    setTimeout(() => {
      if (gridColumnApi) {
        const gridColumns = gridColumnApi.getAllColumns();
        const allColumnIds = [];
        if (gridColumns) {
          gridColumns.forEach(function (column) {
            allColumnIds.push(column.colId);
          });
          gridColumnApi.autoSizeColumns(allColumnIds);
        }
      }
    }, delay);
  }

  loadDirectoryEntity(directoryId: string) {
    const path = "/file-system/get-file-directory/" + directoryId;
    return this.getRequest(path);
  }

  truncate(s: string, start: number = 0, length: number = undefined) {
    const len = s.length;
    if (start < 0) {
      start = len + start;
    }
    if (s.length > length) {
      return s.substr(start, length) + "...";
    }
    return s.substr(start);
  }

  deleteFileById(fileId: string) {
    const path = "/files/delete-file-by-id/" + fileId;
    return this.deleteRequest(path);
  }

  downloadFile(fileId: string, token: string = undefined) {
    if (!fileId) {
      throw new Error("Undefine file path");
    }
    let url = this.getUrl(
      "/files/download?path=" +
      encodeURIComponent(fileId) +
      "&_sid=" +
      encodeURIComponent(this.getAuthorizationToken())
    );

    if (token) {
      url = url + "&access_code=" + token;
    }
    window.open(url);
  }

  download(content, filename, contentType = "application/octet-stream") {
    var a = document.createElement("a");
    var blob = new Blob([content], { type: contentType });
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  }

  prettyJson(jsonString: string) {
    try {
      const doc = JSON.parse(jsonString);
      return JSON.stringify(doc, null, 2);
    } catch (err) { }
  }

  stringify(doc: any) {
    if (doc) {
      return JSON.stringify(doc, null, 2);
    }
  }

  getCountsByMultiNamesAndType(names: string[], type: string): Observable<any> {
    const observable = new Observable((observer) => {
      const obs = [];
      names.forEach((name) => {
        const path = "/counters/counts-by-name-and-type/" + name + "/" + type;
        obs.push(this.getRequest(path));
      });
      forkJoin(obs).subscribe((responseList) => {
        let records = [];
        responseList.forEach((response) => {
          if (Array.isArray(response["records"])) {
            records = records.concat(response["records"]);
          }
        });
        observer.next(records);
      });
    });
    return observable;
  }

  getSelectionText() {
    // var text = "";
    // var doc = document as Document
    // var selection = doc.selection;
    // if (window.getSelection) {
    // 	text = window.getSelection().toString();
    // } else if (selection && selection.type != "Control") {
    // 	text = selection.createRange().text;
    // }
    // return text;
  }

  convertLocalDateToUTCIgnoringTimezone(date: Date) {
    const timestamp = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
    return new Date(timestamp);
  }

  secondsForHumans(milliseconds: number) {
    if (!milliseconds) {
      return;
    }
    let temp = milliseconds / 1000;
    const years = Math.floor(temp / 31536000),
      days = Math.floor((temp %= 31536000) / 86400),
      hours = Math.floor((temp %= 86400) / 3600),
      minutes = Math.floor((temp %= 3600) / 60),
      seconds = temp % 60;

    const secondsPrecision = minutes ? 0 : 2;

    if (days || hours || seconds || minutes) {
      return (
        (years ? years + "y " : "") +
        (days ? days + "d " : "") +
        (hours ? hours + "h " : "") +
        (minutes ? minutes + "m " : "") +
        Number.parseFloat(seconds + "").toFixed(secondsPrecision) +
        "s"
      );
    }

    return "< 1s";
  }

  isObject(value: any) {
    return value !== null && !Array.isArray(value) && value instanceof Object;
  }

  isInteger(s: string) {
    return !isNaN(parseInt(s)) && !isNaN(+s);
  }
  isNumber(value?: string | number): boolean {
    return value != null && value !== "" && !isNaN(Number(value.toString()));
  }
  getTimezones() {
    const path = "/system/available-timezones";
    return this.getRequest(path);
  }

  getAppConfigs(type: string): Observable<any> {
    const path = "/configs/properties-by-type/" + encodeURIComponent(type);
    return this.getRequest(path);
  }

  getAppConfig(type: string, sub_type: string): Observable<any> {
    const path =
      "/configs/get-value/" +
      encodeURIComponent(type) +
      "/" +
      encodeURIComponent(sub_type);
    return this.getRequest(path);
  }

  humanFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }


  findRecordByName(records: any[], value: any, field: string = "name") {
    if (Array.isArray(records)) {
      for (let i = 0; i < records.length; ++i) {
        if (records[i] && records[i][field] == value) {
          return records[i];
        }
      }
    }
  }

  getHeartbeat(
    name: string,
    type: string,
    timeout: number = 60000
  ): Observable<any> {
    const url = "/counters/fetch-heartbeat-response/" + name + "/" + type;
    const params = new HttpParams().append("timeout", timeout);
    return this.getRequest(url, params);
  }

  getPendingBackgroundTask(itemId: string): Observable<any> {
    const url = "/simple-queue/pending-jobs-by-item-id/" + itemId;
    return this.getRequest(url);
  }

  getIfModified(kind: string, record: any): Observable<any> {
    let path = "/long-poll/get-if-modified/" + kind + "/" + record.id;
    let params = new HttpParams().append("mode", "e");
    let since = record.last_modified_on;
    if (since == null) {
      since = new Date();
    } else {
      since = new Date(record.last_modified_on);
    }
    params = params.append("since", since.getTime());
    return this.getRequest(path, params);
  }

  compareId(a: any, b: any) {
    return a && b && a.id === b.id;
  }

  isTextType(type: string) {
    return ["Text", "Textarea", "ID", "Picklist", "Lookup", "UUID", "Email"].includes(
      type
    );
  }

  isNumericType(type: string) {
    return ["Integer", "Bigint", "Long", "Float", "Double", "Phone"].includes(type);
  }

  isTemporalType(type: string) {
    return ["Date", "Timestamp"].includes(type);
  }

  isString(s) {
    return typeof s === "string";
  }

  filterScheduledTaskByItemIdAndType(itemId, type) {
    return this.getRequest(
      `/scheduled-tasks/filter/itemId/${itemId}/type/${type}`
    );
  }
  hasApplicationPermission(permissions: string[]): Observable<any> {
    let params = new HttpParams();
    permissions.forEach((s) => {
      params = params.append("v", s);
    });
    const path = `/authorization/check-permission`;
    return this.getRequest(path, params);
  }

  tryParseJson(s: string) {
    try {
      return JSON.parse(s);
    } catch (err) { }
  }

  generateUUID() {
    var u = "",
      i = 0;
    while (i++ < 36) {
      var c = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"[i - 1],
        r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      u += c == "-" || c == "4" ? c : v.toString(16);
    }
    return u;
  }




  track(id: string, action: any = {}): void {
    if (this.mixpanelInitized) {
      mixpanel.track(id, action);
    }

  }


  setAutoCompletors(selectedTable: any, methodSpecifications: any) {
    const autoCompleteList = [];
    if (selectedTable && selectedTable.columns) {
      selectedTable.columns.forEach((item) => {
        autoCompleteList.push({
          caption: item.name,
          value: item.name,
          meta: "field",
        });
      });
    }
    if (methodSpecifications && methodSpecifications.methods) {
      methodSpecifications.methods.forEach((item) => {
        autoCompleteList.push({
          caption: item.name,
          value: item.name,
          meta: "function",
        });
      });
    }
    autoCompleteList.push({
      caption: "search",
      value: "search",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "select",
      value: "select",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "eval",
      value: "eval",
      meta: "command",
    });

    autoCompleteList.push({
      caption: "limit",
      value: "limit",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "lookup",
      value: "lookup",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "pack",
      value: "pack",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "rename",
      value: "rename",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "sort",
      value: "sort",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "where",
      value: "where",
      meta: "command",
    });

    autoCompleteList.push({
      caption: "drop",
      value: "drop",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "count",
      value: "count",
      meta: "command",
    });
    autoCompleteList.push({
      caption: "regex",
      value: "regex",
      meta: "command",
    });

    autoCompleteList.push({
      caption: "explode",
      value: "explode",
      meta: "command",
    });

    autoCompleteList.push({
      caption: "float",
      value: "float",
      meta: "data type",
    });

    autoCompleteList.push({
      caption: "double",
      value: "double",
      meta: "data type",
    });

    autoCompleteList.push({
      caption: "integer",
      value: "integer",
      meta: "data type",
    });

    autoCompleteList.push({
      caption: "long",
      value: "long",
      meta: "data type",
    });

    autoCompleteList.push({
      caption: "boolean",
      value: "boolean",
      meta: "data type",
    });

    autoCompleteList.push({
      caption: "date",
      value: "date",
      meta: "data type",
    });

    autoCompleteList.push({
      caption: "timestamp",
      value: "timestamp",
      meta: "data type",
    });

    if (selectedTable && Array.isArray(selectedTable.columns)) {
      selectedTable.columns.forEach((col) => {
        autoCompleteList.push({
          caption: col.name,
          value: col.name,
          meta: "column",
        });
      });
    }

    // this.autoCompleteList = autoCompleteList;
    return autoCompleteList;


  }

  loadUIControls(category: string) {
    return new Observable((observer) => {
      const cacheName = "UIControls";
      const data = this.getCacheItem(cacheName, category);
      if (false && data && new Date().getTime() - data['$ts'] > 60000) {
        observer.next(data);
      } else {
        return this.getRequest(`/authorization/ui-controls/${category}`).subscribe(res => {
          if (this.checkError(res)) {
            observer.next(res);
          } else {
            const out = {};
            if (Array.isArray(res)) {
              res.forEach(r => {
                out[r] = true;
              });
            }
            out['$ts'] = new Date().getTime();
            this.putCacheItem(cacheName, category, out);
            observer.next(out);
          }
        });
      }
    });
  }


  notify(type: string, message: string,) {

    if (type === "success") {
      this.snackBar.open(message, 'Close', {
        panelClass: ['success-snackbar'],
      });
    } else if (type === "error") {
      this.snackBar.open(message, 'Close', {
        panelClass: ['error-snackbar'],
      });
    } else if (type === "info") {
      this.snackBar.open(message, 'Close', {
        panelClass: ['info-snackbar'],
      });
    } else if (type === "warning") {
      this.snackBar.open(message, 'Close', {
        panelClass: ['warning-snackbar'],
      });
    }
  }

  hideAll() {
    this.snackBar.dismiss();
  }


  matTableColumnGenerator(col: any) {
    let columns = [];
    if (Array.isArray(col)) {
      col.forEach((item) => {
        columns.push(item.name);
      })
      return columns;
    }
  }

  extractKey(obj: any) {
    let keysArray = [];

    let keys = Object.keys(obj[0]);
    keys.forEach(key => {
      keysArray.push(key);
    })

    return keysArray;
  }


  setSelectedAppControls(controls: any) {
    this.selectedApp.next(controls);
  }

  getSelectedAppControls(): Observable<string> {
    return this.selectedApp.asObservable();
  }




  downloadCSV(data: any[], filename: string) {
    const csv = this.convertArrayOfObjectsToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  convertArrayOfObjectsToCSV(array: any[]): string {
    const header = Object.keys(array[0]).join(',');
    const rows = array.map(obj => {
      return Object.values(obj).map(value => {
        if (typeof value === 'object') {
          return JSON.stringify(value);
        }
        return value;
      }).join(',');
    }).join('\n');
    return header + '\n' + rows;
  }


  downloadRawText(data: string, filename: string) {
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  copyProps(src: any, target: any) {
    Object.keys(src).forEach(key => {
      src[key] = target[key];
    });
  }



  clearAuditFields(obj: any) {
    // delete obj?.id;
    // delete obj?.created_by_id;
    // delete obj?.created_by_id_name;
    // delete obj?.created_on;
    // delete obj?.last_modified_on;
    // delete obj?.last_modified_by_id;
    // delete obj?.owner_id;
    // delete obj?.owner_id_name;
    // delete obj?.last_modified_by_id_name;
    // delete obj?.guid;
    // delete obj?.acc_id;
    // delete obj?.share;
    // delete obj?.created_by;
    // delete obj?.last_modified_by;
    // delete obj?.owner;
    const data = obj;

    const auditFields = [
      "id",
      "created_by_id",
      "created_by_id_name",
      "created_on",
      "last_modified_on",
      "last_modified_by_id",
      "owner_id",
      "owner_id_name",
      "last_modified_by_id_name",
      "guid",
      "acc_id",
      "share",
      "created_by",
      "last_modified_by",
      "owner"
    ];

    auditFields.forEach((k) => {
      if (data[k]) {
        delete data[k];
      }
    });

    return data;
  }


  displayMode(): DisplayMode {
    // const isMobile =  window.matchMedia('(min-width: 600px)');
    // { media: '(min-width: 600px)', matches: false, onchange: null }


    const screenWidth = window.innerWidth;
    if (screenWidth > 1280) {
      return DisplayMode.Wide;
    }
    if (screenWidth < 1280) {
      return DisplayMode.Narrow;
    }

    return DisplayMode.Wide;

  }

  extract_address(obj: any, prefix: string) {
    // prefix will be one of these three options: shipping, billing, biller
    const address = {
      'street1': obj[`${prefix}_street1`],
      'street2': obj[`${prefix}_street2`],
      'city': obj[`${prefix}_city`],
      'state': obj[`${prefix}_city`],
      'country': obj[`${prefix}_country`],
      'postal_code': obj[`${prefix}_postal_code`]
    };

    return address;

  }

  getNavbarResponseCache(): any {
    if (!this._navbar_data) {
      const content = localStorage.getItem("aio-navbar");
      try {
        this._navbar_data = JSON.parse(content);
      } catch (err) {
        console.error("Failed to parsed cached navbar response: " + content, err);
      }
    }
    return this._navbar_data;
  }

  getNavbarResponse(cache: boolean = true): Observable<any> {
    return new Observable((observer) => {

      const cache = this.getNavbarResponseCache();
      if (false) {
        observer.next(cache);
        observer.complete();
      } else {
        const path = `/users/navbar`;
        return this.getRequest(path).subscribe(res => {
          if (!this.checkError(res)) {
            if (res.user) {
              res.user.home_page = res.home_page;
            }
            this._navbar_data = res;
            localStorage.setItem("aio-navbar", JSON.stringify(res));
          }
          observer.next(res);
          observer.complete();
        });
      }
    });
  }


  hasPermession(appName) {
    let permission = this._navbar_data?.apps?.filter((app) => {
      return app === appName
    })
    console.log(permission, "send-request permission")
    return permission?.length > 0;
  }

  getSelectedApp() {
    return localStorage.getItem("SELECTED_APP");
  }


  // getFileExtension(fileName: string) {
  //   // let extension = fileName.match('[^.]+$');
  //   let extension = fileName.split('.').pop().toLowerCase();
  //   return extension;
  // }

  isEmpty(obj: any) {
    if (!obj) {
      return true;
    }
    for (const prop in obj) {
      return false;
    }
    return true;
  }


  getFileExtension(filename) {
    if (typeof filename !== 'string') return null;
    const parts = filename.split('.');

    if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {
      return '';
    }

    return parts.slice(-2).join('.');
  }

  removeKeys(obj: any, keysToRemove: string[]) {

    if (Array.isArray(obj)) {
      obj.forEach(item => this.removeKeys(item, keysToRemove));
    } else if (typeof obj === 'object' && obj !== null) {
      for (let key in obj) {
        if (keysToRemove.includes(key)) {
          delete obj[key];
        } else if (typeof obj[key] === 'object') {
          this.removeKeys(obj[key], keysToRemove);
        }
      }
    }

    return obj;
  }

  reorderObjectArray(list: any, order: string[]) {
    const orderedArray = [];

    order.forEach(name => {
      const found = list.find(item => item.name === name);
      if (found) {
        orderedArray.push(found);
      }
    });

    list.forEach(item => {
      if (!order.includes(item.name)) {
        orderedArray.push(item);
      }
    });

    return orderedArray;
  }

  isJsonEmpty(obj: any) {
    return Object.getOwnPropertyNames(obj).length === 0;;
  }





  groupTopicByModule(data: any) {
    const extractModuleNumber = (module) => {
      return parseInt(module.split('.')[0], 10);
    };
    if (Array.isArray(data)) {

      // Step 1: Group by main_topic and module
      const groupedByMainTopic = data.reduce((acc, item) => {
        const { main_topic, module, ...rest } = item;
        if (!acc[main_topic]) {
          acc[main_topic] = {};
        }
        if (!acc[main_topic][module]) {
          acc[main_topic][module] = [];
        }
        acc[main_topic][module].push({ ...rest });
        return acc;
      }, {});

      // Step 2: Collect main_topics and concatenate modules
      const mainTopics = Object.keys(groupedByMainTopic);
      const allModules = [];

      mainTopics.forEach(mainTopic => {
        const moduleGroups = groupedByMainTopic[mainTopic];
        const sortedModules = Object.keys(moduleGroups).map(module => {
          const items = moduleGroups[module];
          const sortedItems = items.sort((a, b) => a.name.localeCompare(b.name));
          return {
            module: module.trim(),
            content: sortedItems
          };
        }).sort((a, b) => extractModuleNumber(a.module) - extractModuleNumber(b.module));

        allModules.push(...sortedModules);
      });

      return {
        main_topic: mainTopics,
        modules: allModules
      };
    } else {
      return undefined;
    }
  }


  formatDuration(totalSeconds: number, appendUnits: boolean = true) {

    if (!totalSeconds) {
      return;
    }

    const hours = Math.floor(totalSeconds / 3600);
    const remainingSecondsAfterHours = totalSeconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSeconds = Math.round(remainingSecondsAfterHours % 60);

    let output = '';

    if (hours) {
      output = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
      if (appendUnits) {
        output += ' hours';
      }
    } else {
      output = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
      if (appendUnits) {
        output += ' minutes';
      }
    }
    return output;

  }

  validatePassword(password: string) {
    let messages = [];

    if (password.length < 8) {
      messages.push({ type: "Error", message: " Password must be at least 8 character." });
    }


    if (!/[A-Z]/.test(password)) {
      messages.push({ type: "Error", message: "Password must contain at least one uppercase letter." });
    } else {
      messages.push({ type: "Success", message: "Password contains at least one uppercase letter." });
    }

    if (!/[a-z]/.test(password)) {
      messages.push({ type: "Error", message: "Password must contain at least one lowercase letter." });
    } else {
      messages.push({ type: "Success", message: "Password contains at least one lowercase letter." });
    }

    if (!/[0-9]/.test(password)) {
      messages.push({ type: "Error", message: "Password must contain at least one number." });
    } else {
      messages.push({ type: "Success", message: "Password contains at least one number." });
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      messages.push({ type: "Error", message: "Password must contain at least one symbol character." });
    } else {
      messages.push({ type: "Success", message: "Password contains at least one symbol character." });
    }

    return messages;
  }


  fetch_user_headers(userIds: Array<number>) {
    let params = new HttpParams();
    for (let id of userIds) {
      params = params.append("id", id);
    }
    return this.getRequest('/users/headers', params);
  }

  fill_user_headers(objects: Array<any>, fields: Array<string> = []) {
    const ids = new Set<number>();

    fields = [...fields];
    fields.push("created_by_id");
    fields.push("last_modified_by_id");
    fields.push("owner_id");
    fields = [...new Set<string>(fields)];

    for (let rec of objects) {
      for (let field of fields) {
        if (rec[field]) {
          ids.add(rec[field]);
        }
      }
    }
    if (ids.size > 0) {
      this.fetch_user_headers([...ids]).subscribe(userMap => {
        if (!this.checkError(userMap)) {
          for (let rec of objects) {
            for (let field of fields) {
              if (rec[field]) {
                rec[field + "__r"] = userMap[rec[field]];
              }
            }
          }
        }
      });
    }


  }




}
