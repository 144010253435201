<div class=" d-flex align-items-center justify-content-center position-relative ">
    <div *ngIf="(loader.isLoading$ | async)" class="spinner-overlay">
    <!-- <div class="spinner-overlay"> -->
        <div class="loader-container">
            <img src="/assets/images/avalanchio-loader.png" alt="logo" height="70" />
            <!-- <mat-spinner diameter="50"></mat-spinner> -->
            
                <mat-progress-bar style="max-width:500px; margin-top: 1rem;" class="progress-bar-animated" mode="indeterminate"></mat-progress-bar>
      
        </div>
    </div>

    <!-- </div> -->
</div>